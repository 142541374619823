// var $ = require('jQuery');
global.jQuery     = require('./Vendor/jquery-3.4.1.min.js');
// var debounce   = require('js-throttle-debounce');
// var initIt       = require('initIt');
// import Swiper from 'swiper';
// var lottie       = require('./Vendor/lottie.js');
// var flickIt        = require('flickIt');
// var lottie        = require('lottie-web');
// var cookIt        = require('cookIt');
var Isotope    = require('isotope-layout');
// var niceSelect = require('./Vendor/jquery.nice-select.min.js');
// var WOW        = require('./Vendor/wow.min.js');

var Flickity   = require('flickity');

(function($) {
// $(document).ready(function() {
// $(document).on('ready', function() {


// $('html').addClass('is_fading');

/*===================================
=            global vars            =
===================================*/

var windowWidth = $(window).width();

/*===================================
=            repeatOften            =
===================================*/

function repeatOften() {

    if ($(window).scrollTop() != 0) {
        if (!$('nav').hasClass('is-scrolled')) {
            $('nav').addClass('is-scrolled');
        }
    } else {
        if ($('nav').hasClass('is-scrolled')) {
            $('nav').removeClass('is-scrolled');
        }
    }
}

$(window).on('scroll', function() {
    requestAnimationFrame(repeatOften);
});



// if($('.speakerPool').length){
//     console.log('speaker');
//     var $container = $('.speakerPool');
//     console.log($container.data('isotope'));
//     var isoSpeakers = $container.data('isotope');
//     // isoSpeakers.arrange({ filter: 'tschechien' });
// }

if($('.speakerPool').length){

    var speakerpoolInitiated = true;

    // var initialCountryKey = 1;
    var initialCountryKey = 1;
    console.log(curLang, 'curLang');
    if(curLang=='de'){
        //initialCountryKey = 1;
    } else {
        // $($('.countries .countryFilter')[0]).insertAfter($('.countries .countryFilter')[1]);
        // $($('.countries .countryFilter')[1]).insertAfter($('.countries .countryFilter')[0]);
    }

    $($('.speakerFilters .countryFilter')[initialCountryKey]).addClass('active');
    var initialFilter = $($('.speakerFilters .countryFilter')[initialCountryKey]).data('class');

    var setFilters = [];

    setFilters.push(initialFilter);
    initialFilter = '.'+initialFilter;

    // console.log(findGetParameter('country'));
    var countryVal = findGetParameter('country');

    // if (countryVal == 'cz') {
    //     initialFilter = '.tschechien';
    // }

    // console.log(initialFilter);

    // isoSpeakers = new Isotope('.speakerPool',{
    //     itemSelector: '.speakerItem',
    //     layoutMode: 'masonry',
    //     filter: initialFilter
    // });
    var lang = $('html').attr('data-lang');
    if (countryVal !== null && countryVal.length) {
        $($('.speakerFilters .countryFilter')).removeClass('active');
        if (countryVal == 'deutschland') {
            if (lang == 'en') {
                $('.speakerFilters .countryFilter[data-class="germany"]').trigger('click');
                $($('.speakerFilters .countryFilter')[2]).addClass('active');
            } else {
                $($('.speakerFilters .countryFilter')[0]).addClass('active');
            }
        }
        if (countryVal == 'oesterreich') {
            if (lang == 'en') {
                $('.speakerFilters .countryFilter[data-class="austria"]').trigger('click');
                $($('.speakerFilters .countryFilter')[0]).addClass('active');
            } else {
                $($('.speakerFilters .countryFilter')[1]).addClass('active');
            }
        }
        if (countryVal == 'tschechien') {
            if (lang == 'en') {
                $('.speakerFilters .countryFilter[data-class="czech-republic"]').trigger('click');
                $($('.speakerFilters .countryFilter')[1]).addClass('active');
            } else {
                $($('.speakerFilters .countryFilter')[2]).addClass('active');
            }
        }
        if (countryVal == 'ungarn') {
            if (lang == 'en') {
                $('.speakerFilters .countryFilter[data-class="hungary"]').trigger('click');
            }
            $($('.speakerFilters .countryFilter')[3]).addClass('active');
        }
    }

    // $('.speakerFilters .countryFilter[data-class="' + getUrlVars().country + '"]').trigger('click');
}

function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    var items = location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

// console.log('hae');

if ($('.js_openAccordion').length) {
    $(document).on('click', '.js_openAccordion', function() {

        // console.log('hello?');
        
        if ($(this).hasClass('is-active')) {
            $(this).removeClass('is-active');
            $(this).find('.text-wrapper').css('max-height', 0);
            $(this).find('.indicator').html('+');
        } else {
            $('.section-accordion .item').removeClass('is-active');
            $('.section-accordion .text-wrapper').css('max-height', 0);
            $('.section-accordion .indicator').html('+');
            $(this).addClass('is-active');
            $(this).find('.text-wrapper').css('max-height', 'unset');
            $(this).find('.indicator').html('-');
        }
    });
}


/*=======================================
=               swipers                 =
=======================================*/

// setTimeout(() => {
//     console.log($('.section-teaserBox .swiper'), 'jquery el')
//     var flkty = $('.section-teaserBox .swiper').data('flickity');
//     console.log(flkty, 'flkty')
// }, 1000);

// setTimeout(() => {
//     // $(el).flickity('destroy')
//     console.log($('.section-teaserBox .swiper'), 'el')

//     // var flkty = $('.section-teaserBox .swiper').data('flickity');
//     // $carousel = $('.section-teaserBox .swiper').flickity();
//     // console.log(carousel, 'carousel')

//     // $(window).on('load',function(){
//         // var flktyEl = document.querySelectorAll('.section-teaserBox .swiper')
//         var flktyEl = document.getElementsByClassName('swiper')

//         for (let index = 0; index < flktyEl.length; index++) {
//             const element = flktyEl[index];
//             // console.log(element, 'fltyEl')
//             var flkty = $(element).data('flickity');
//             // var flkty = $carousel.data('flickity');
//             // var flkty = Flickity.data( element )
//             console.log(flkty, 'flkty')
//         }


//         // console.log(flktyEl, 'fltyEl')
//         // var flkty = Flickity.data( flktyEl )
//         // console.log(flkty, 'flkty')
//     // });

//     // flkty.flickity( {dragable: false} );
// }, 2000);

if($('.section-teaserBox .swiper-new').length){
    $('.section-teaserBox .swiper-new').each(function(k,el){
        
        // setTimeout(() => {
        //     // $(el).flickity('destroy')
        //     console.log($(el), 'el')

        //     var flkty = $(el).data('flickity');
        //     console.log(flkty, 'flkty')
        //     // flkty.flickity( {dragable: false} );
        // }, 10000);


        var orientation = $(el).data('orientation');
        var blogflkty = new Flickity( el, {
            cellSelector: '.slide',
            pageDots: true,
            wrapAround: true,
            prevNextButtons: true,
            imagesLoaded: true,
            resize: true,
            draggable: false,
        });

    });
}

/*====================================
=            resize stuff            =
====================================*/

// console.log('hello dear?');



setTimeout(function() {
    // $('.animation-start').addClass('is-done');
}, 6200);


setTimeout(function() {
    if ($('.applynow').length) {
        $('.applynow').removeClass('apply-hidden');
    }
}, 2000);
// });
// $(window).load(function() {
// });

})(jQuery);
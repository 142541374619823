
; $ = global.$ = require("/home/timotheus/html/maxfive/node_modules/jquery/dist/jquery.js");
require("flickity");
require("flickity-imagesloaded");
require("flickity-fade");
require("js-throttle-debounce");
; var __browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
// var $ = require('jQuery')
var Flickity   = __browserify_shim_require__('flickity');
// var Flickity   = require('flickity-fade');
// var Flickity = require('flickity-imagesloaded');
var debounce   = __browserify_shim_require__('js-throttle-debounce');

    var flickIt = {

        resizeModule: function() {
            if (!$('html').hasClass('isIos') && !$('html').hasClass('isAndroid')) {
                // flickIt.initSliders("reload");
            }
        },
        initSliders: function(action) {
            if ($(".slider").length) {
                $(".slider").each(function() {
                    var id = $(this).attr("id");
                    var ww = $(window).outerWidth();
                    var actOpt = undefined;

                    // if ($(this).hasClass('slider-pag')) {
                    if ($(this).hasClass('slider-img')) {
                        

                    // } else if($(this).hasClass('slider-drinktype')) {

                        if (ww <= 1024) {
                            var actOpt = {

                                contain: true,
                                // percentPosition: true,
                                prevNextButtons: false,
                                setGallerySize: true,
                                adaptiveHeight: false,
                                cellAlign: "left",
                                // cellAlign: leftOffset / sliderWidth,
                                pageDots: false,
                                wrapAround: true,
                                groupCells: 1,
                                imagesLoaded: true,

                                selectedAttraction: 0.01,
                                friction: 0.2
                            };
                        } else {
                            var actOpt = {
                                contain: true,
                                // percentPosition: true,
                                prevNextButtons: false,
                                setGallerySize: true,
                                adaptiveHeight: false,
                                cellAlign: "left",
                                // cellAlign: leftOffset / sliderWidth,
                                pageDots: false,
                                wrapAround: true,
                                groupCells: 1,
                                imagesLoaded: true,

                                selectedAttraction: 0.01,
                                friction: 0.2
                            };
                        }

                    

                    // if ($(this).hasClass('slider-tag')) {
                        
                    // }
                    // else if($(this).hasClass('slider-img')){
                    //     if (ww <= 1024) {
                    //         var actOpt = {

                    //             contain: true,
                    //             // percentPosition: true,
                    //             prevNextButtons: false,
                    //             setGallerySize: true,
                    //             adaptiveHeight: false,
                    //             cellAlign: "left",
                    //             // cellAlign: leftOffset / sliderWidth,
                    //             pageDots: false,
                    //             wrapAround: true,
                    //             groupCells: 1,
                    //             imagesLoaded: true
                    //         };
                    //     } else {
                    //         var actOpt = {
                    //             contain: true,
                    //             // percentPosition: true,
                    //             prevNextButtons: false,
                    //             setGallerySize: true,
                    //             adaptiveHeight: false,
                    //             cellAlign: "left",
                    //             // cellAlign: leftOffset / sliderWidth,
                    //             pageDots: false,
                    //             wrapAround: true,
                    //             groupCells: 1,
                    //             imagesLoaded: true

                    //         };
                    //     }
                    // } 
                    
                    } else if($(this).hasClass('slider-tag')){

                        var actOpt = {
                            contain: true,
                            // percentPosition: true,
                            prevNextButtons: false,
                            setGallerySize: true,
                            adaptiveHeight: false,
                            cellAlign: "left",
                            // cellAlign: leftOffset / sliderWidth,
                            pageDots: false,
                            wrapAround: false,
                            groupCells: 1,
                            imagesLoaded: true,
                            draggble: '>3',

                            selectedAttraction: 0.01,
                            friction: 0.2
                        };

                     } else if($(this).hasClass('slider-menu')){

                        var actOpt = {
                            contain: false,
                            percentPosition: false,
                            prevNextButtons: false,
                            cellSelector: '.tag-wrapper-large',
                            setGallerySize: true,
                            adaptiveHeight: true,
                            cellAlign: 'right',
                            rightToLeft: true,
                            // cellAlign: leftOffset / sliderWidth,
                            pageDots: false,
                            wrapAround: false,
                            groupCells: 1,
                            // imagesLoaded: true,
                            // draggble: '>3',

                            // selectedAttraction: 0.01,
                            // friction: 0.2
                        };

                     } else {

                        if (ww <= 1024) {
                            var actOpt = {

                                contain: true,
                                // percentPosition: true,
                                prevNextButtons: false,
                                setGallerySize: true,
                                adaptiveHeight: false,
                                cellAlign: "left",
                                // cellAlign: leftOffset / sliderWidth,
                                pageDots: false,
                                wrapAround: true,
                                groupCells: 1,
                                imagesLoaded: true,
                                selectedAttraction: 0.01,
                                friction: 0.2
                            };
                        } else {
                            var actOpt = {
                                contain: true,
                                // percentPosition: true,
                                prevNextButtons: false,
                                setGallerySize: true,
                                adaptiveHeight: false,
                                cellAlign: "left",
                                // cellAlign: leftOffset / sliderWidth,
                                pageDots: false,
                                wrapAround: true,
                                groupCells: 1,
                                imagesLoaded: true,
                                selectedAttraction: 0.01,
                                friction: 0.2

                            };
                        }
                    }


                    if (action == "reload") {

                        var elem = document.getElementById(id);
                        var flkty = Flickity.data(elem);

                        flkty.destroy();
                    }

                    flickIt.initSlider(id, actOpt);
                });
            }
        },
        initSlider: function(e, o) {
            // var elem = document.querySelector(e);
            var elem = document.getElementById(e);
            var options = {};
            if (o != undefined) {
                options = o;
            } else {
                options = {
                    cellAlign: "left",
                    contain: true,
                    // accessibility: false,
                    percentPosition: true,
                    autoPlay: 5000,
                    // freeScroll: true,
                    prevNextButtons: false,
                    setGallerySize: true,
                    // adaptiveHeight: true,
                    pageDots: false,
                    wrapAround: true
                };
            }

            setTimeout(function() {
                // console.log(elem, 'elem')
                var flkty = new Flickity(elem, options);
                var event = "click";
                var www = $(window).outerWidth();


                // var $prevB = $(elem).parent().next().find('.button-prev');
                // var $nextB = $(elem).parent().next().find('.button-next');


                if ($(elem).find(".slider-buttons").length > 0) {
                    var $prevB = $(elem)
                        .find(".slider-buttons")
                        .find(".slider-button-prev");

                    var $nextB = $(elem)
                        .find(".slider-buttons")
                        .find(".slider-button-next");

                } else if($(elem).closest(".section").length > 0){
                    var $prevB = $(elem)
                        .closest(".section")
                        .find(".slider-button-prev");

                    var $nextB = $(elem)
                        .closest(".section")
                        .find(".slider-button-next");                    
                } else {
                    var $prevB = $(elem)
                        .closest(".tags-menu-inner")
                        .find(".slider-button-prev");

                    var $nextB = $(elem)
                        .closest(".tags-menu-inner")
                        .find(".slider-button-next"); 
                }


                if (options.draggable !== false) {
                    elem.ontouchmove = function (e) { // prevent mobile scroll out of viewport (whobble)
                        e.preventDefault();
                    }
                    document.ontouchmove = function (e) { // Enable scrolling.
                        return true; // Not use e.preventDefault()
                    }


                    // prevent click when items are links on drag
                    $(elem).on('dragStart.flickity', () => $(elem).css('pointer-events', 'none'));
                    $(elem).on('dragEnd.flickity', () => $(elem).css('pointer-events', 'all'));
                }

                var buttons = [$prevB, $nextB];

                $.each(buttons, function(index, el) {
                    $(el).unbind(event);
                    $(el).on(event, function(evt) {
                        evt.stopPropagation();
                        evt.preventDefault();
                        // console.log(event);

                        // console.log(flkty.slides.length, 'length:');
                        // console.log(index, 'index:');

                        console.log('button_click');

                        if ($(this).hasClass("slider-button-next")) {
                            flkty.next(false, false);
                        } else {
                            flkty.previous(false, false);
                        }

                    });
                });

                // flkty.on( 'select', function( index ) {
                //     console.log(index);
                // });

                // flkty.on( 'staticClick.flickity', function( event, pointer, cellElement, cellIndex ) {
                //     console.log('click ha?')
                //     if ( typeof cellIndex == 'number' ) {
                //         flkty.flickity( 'select', cellIndex );
                //     }
                // });

                // $cellButtonGroup = $('.slider-item-pag');

                $(document).on( 'click', '.slider-item-pag', function() {
                    // flkty.flickity( 'select', index );

                    var $sliderImg = $(this).closest('.section-Swiper').find('.slider-img');
                    var $sliderText = $(this).closest('.section-Swiper').find('.slider-text');
                    var sliderImgId = $sliderImg.attr('id');
                    var sliderTextId = $sliderText.attr('id');
                    var index = $(this).index();

                    if (flkty.element.id == sliderImgId) {
                        flkty.select(index);
                        if (!$(this).hasClass('is-selected')) {
                            $('.slider-item-pag').removeClass('is-selected');
                            $(this).addClass('is-selected');
                        }
                    }
                    if (flkty.element.id == sliderTextId) {
                        flkty.select(index);
                        // if (!$(this).hasClass('is-selected')) {
                        //     $('.slider-item-pag').removeClass('is-selected');
                        //     $(this).addClass('is-selected');
                        // }
                    }
                    // $slider.flickity( 'select', index );
                    // $slider.select(index);

                });


                flkty.on( 'change', function( index ) {


                    // var $slider = $(elem);
                    // var $section = $slider.closest('.section');
                    // var $sliderButtons = $section.find('.slider-buttons');



                    // var index = $(this).index();
                    // flkty.select(index);

                    setTimeout(function() {

                        var ww = $(window).outerWidth();
                        // $(elem).hasClass('slider-insights')

                        

                        
                    }, 10);

                });

            }, 100);
        },

    }

    // window.onresize = flickIt.resizeModule.debounce(250);

    flickIt.initSliders("init");

    // return flickIt;
    flickIt.exports = flickIt;
; browserify_shim__define__module__export__(typeof flickIt != "undefined" ? flickIt : window.flickIt);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });


; $ = global.$ = require("/home/timotheus/html/maxfive/node_modules/jquery/dist/jquery.js");
; var __browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
// var $ = require('jQuery');

var mapIt = {

    map: false,
    markers: {},

    initGmaps: function(action) {
        if ($('#gmaps').length != 0) {

            // if ('pad-' in mbiMq.mqArea) {
            //     var lat = 50.438070;
            //     var lng = 7.398080;
            //     var drag = false;
            //     var scrolli = false;
            // } else {
                var lat = 50.438070;
                var lng = 7.398080;
                var drag = true;
                var scrolli = false;
            // }

            // var zoom = 14;
            // if ('paw-' in mbiMq.mqArea) {
                zoom = 14;
            // }

            mapIt.map = new GMaps({
                div: '#gmap',
                lat: lat,
                lng: lng,
                zoom: zoom,
                draggable: drag,
                // refresh:true,
                scrollwheel:scrolli,
                styles: [
{
"elementType": "geometry",
"stylers": [
  {
    "color": "#f5f5f5"
  }
]
},
{
"elementType": "geometry.fill",
"stylers": [
  {
    "color": "#e5f1e8"
  }
]
},
{
"elementType": "labels.icon",
"stylers": [
  {
    "visibility": "off"
  }
]
},
{
"elementType": "labels.text.fill",
"stylers": [
  {
    "color": "#616161"
  }
]
},
{
"featureType": "administrative.land_parcel",
"elementType": "labels.text.fill",
"stylers": [
  {
    "color": "#bdbdbd"
  }
]
},
{
"featureType": "poi",
"elementType": "geometry",
"stylers": [
  {
    "color": "#eeeeee"
  }
]
},
{
"featureType": "poi",
"elementType": "labels.text.fill",
"stylers": [
  {
    "color": "#757575"
  }
]
},
{
"featureType": "poi.park",
"elementType": "geometry",
"stylers": [
  {
    "color": "#e5e5e5"
  }
]
},
{
"featureType": "poi.park",
"elementType": "geometry.fill",
"stylers": [
  {
    "color": "#b9cbc1"
  }
]
},
{
"featureType": "poi.park",
"elementType": "geometry.stroke",
"stylers": [
  {
    "color": "#b9cbc1"
  }
]
},
{
"featureType": "poi.park",
"elementType": "labels.text.fill",
"stylers": [
  {
    "color": "#9e9e9e"
  }
]
},
{
"featureType": "road",
"elementType": "geometry",
"stylers": [
  {
    "color": "#ffffff"
  }
]
},
{
"featureType": "road.arterial",
"elementType": "geometry.fill",
"stylers": [
  {
    "color": "#dbe8de"
  }
]
},
{
"featureType": "road.arterial",
"elementType": "geometry.stroke",
"stylers": [
  {
    "color": "#dbe8de"
  }
]
},
{
"featureType": "road.arterial",
"elementType": "labels.text.fill",
"stylers": [
  {
    "color": "#757575"
  }
]
},
{
"featureType": "road.highway",
"elementType": "geometry",
"stylers": [
  {
    "color": "#dadada"
  }
]
},
{
"featureType": "road.highway",
"elementType": "geometry.fill",
"stylers": [
  {
    "color": "#bfcbc2"
  }
]
},
{
"featureType": "road.highway",
"elementType": "labels.text.fill",
"stylers": [
  {
    "color": "#616161"
  }
]
},
{
"featureType": "road.highway.controlled_access",
"elementType": "geometry.fill",
"stylers": [
  {
    "color": "#bfcbc2"
  }
]
},
{
"featureType": "road.highway.controlled_access",
"elementType": "geometry.stroke",
"stylers": [
  {
    "color": "#bfcbc2"
  }
]
},
{
"featureType": "road.local",
"elementType": "geometry.fill",
"stylers": [
  {
    "color": "#d8e5dc"
  }
]
},
{
"featureType": "road.local",
"elementType": "geometry.stroke",
"stylers": [
  {
    "color": "#d8e5dc"
  }
]
},
{
"featureType": "road.local",
"elementType": "labels.text.fill",
"stylers": [
  {
    "color": "#9e9e9e"
  }
]
},
{
"featureType": "transit.line",
"elementType": "geometry",
"stylers": [
  {
    "color": "#e5e5e5"
  }
]
},
{
"featureType": "transit.station",
"elementType": "geometry",
"stylers": [
  {
    "color": "#eeeeee"
  }
]
},
{
"featureType": "water",
"elementType": "geometry",
"stylers": [
  {
    "color": "#c9c9c9"
  }
]
},
{
"featureType": "water",
"elementType": "geometry.fill",
"stylers": [
  {
    "color": "#7e8580"
  }
]
},
{
"featureType": "water",
"elementType": "labels.text.fill",
"stylers": [
  {
    "color": "#9e9e9e"
  }
]
}
]
                // featuretype list: https://developers.google.com/maps/documentation/javascript/style-reference#style-features
                // https://mapstyle.withgoogle.com/
            });

            var $urlPrefix = $('html').attr('data-root-url');

            var image = {
                url: $urlPrefix + '/Assets/Svg/google_pin.png'
            }

            var marker = {
                lat: 50.438070,
                lng: 7.398080,
                icon:image,
                click: function(e) {
                    window.open('https://www.google.at/maps/place/Wehrbr%C3%BCcklstra%C3%9Fe+6,+1220+Wien/@48.2171665,16.526297,17z/data=!3m1!4b1!4m5!3m4!1s0x476d01c81c6295bf:0xc482464271933bf6!8m2!3d48.2171629!4d16.5284857');
                }
//

            }
            console.log(mapIt.map);
            // mapIt.map.addMarker(marker);


        }
    }
}

        
mapIt.initGmaps();
mapIt.exports = mapIt;
; browserify_shim__define__module__export__(typeof mapIt != "undefined" ? mapIt : window.mapIt);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });

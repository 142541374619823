
; $ = global.$ = require("/home/timotheus/html/maxfive/node_modules/jquery/dist/jquery.js");
; var __browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);

var initIt = {
    checkTouch: function() {
        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        var mq = function(query) {
            return window.matchMedia(query).matches;
        }

        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    },
    /*====================================
    =            observer img            =
    ====================================*/
    observerImg: function() {
        var imgs = document.querySelectorAll('.lazyIt');

        var observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    entry.target.src = entry.target.dataset.src;

                    if (entry.target.dataset.srcset != undefined && entry.target.dataset.srcset !== false) {
                        entry.target.srcset = entry.target.dataset.srcset;
                    }
                    entry.target.classList.add('loaded');

                    observer.unobserve(entry.target);
                    console.log('in view');
                } else {
                // console.log('out of view');
                }
            });
        });

        imgs.forEach(image => {
          observer.observe(image);
        });
    },
    setBrowsers: function() {
        var browsers = {};

        // Opera 8.0+
        browsers.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        // Firefox 1.0+
        browsers.isFirefox = typeof InstallTrigger !== 'undefined';
        // Safari 3.0+ "[object HTMLElementConstructor]" 
        browsers.isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
        // Internet Explorer 6-11
        browsers.isIE = /*@cc_on!@*/false || !!document.documentMode;
        browsers.isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        // Edge 20+
        browsers.isEdge = !browsers.isIE && !!window.StyleMedia;
        // Chrome 1+
        browsers.isChrome = !!window.chrome && !!window.chrome.webstore;

        browsers.isWindowsPhone = (/windows phone/i.test(navigator.userAgent || navigator.vendor || window.opera));
        browsers.isAndroid = (/android/i.test(navigator.userAgent || navigator.vendor || window.opera));
        browsers.isIos = (/iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor || window.opera)) && (!window.MSStream);

        for (var key in browsers) {
            if (browsers.hasOwnProperty(key)) {
                // console.log(key + " -> " + browsers[key]);
                if (browsers[key]) {
                    $('html').addClass(key);
                }
            }
        }
    }
}
initIt.observerImg();
initIt.setBrowsers();
initIt.touch = initIt.checkTouch();
initIt.exports = initIt;
; browserify_shim__define__module__export__(typeof initIt != "undefined" ? initIt : window.initIt);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });
